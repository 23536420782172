<template>
  <div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="banner">
      <Banner></Banner>
    </div>

    <div class="page-body">
      <BreadcrumbNavi></BreadcrumbNavi>
      <div class="wrap">
        <div class="contact flex">
          <div class="contact-img">
            <img src="../assets/images/map.png" alt="" />
          </div>
          <div class="contact-text">
            <h3>银川芝兰互联网医院有限公司</h3>
            <p>邮编：750000</p>
            <p>电话：09518732574</p>
            <p>地址：宁夏银川市兴庆区贺兰山东路帕克中心B座 2003 2005</p>
            <p>官网：www.ycslan.com</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";
import BreadcrumbNavi from "@/components/BreadcrumbNavi.vue";

export default {
  components: {
    Header,
    Banner,
    Footer,
    BreadcrumbNavi,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.contact {
  padding-top: 40px;
  margin-bottom: 250px;
  .contact-img {
    width: 460px;
    margin-right: 60px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .contact-text {
    padding-top: 70px;
    width: 56%;
    h3 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    p {
      font-size: 15px;
      color: #666;
      line-height: 2;
    }
  }
}
</style>
